import React, { createContext, useState } from 'react'

export const AppContext = createContext()

const Provider = ({ children }) => {
  const [buttonColor, setButtonColor] = useState('#fff')
  const [showGlow, setShowGlow] = useState(false)
  const [showRipple, setShowRipple] = useState(false)

  return (
    <AppContext.Provider value={{
      buttonColor,
      showGlow,
      showRipple,
      setButtonColor: hex => setButtonColor(hex),
      setShowGlow: bool => setShowGlow(bool),
      setShowRipple: bool => setShowRipple(bool),
    }}>
      {children}
    </AppContext.Provider>
  )
}

export default ({ element }) => (
  <Provider>
    {element}
  </Provider>
)